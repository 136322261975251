import loadable from "@loadable/component"

const loading = <h4>Loading...</h4>;

// const Home = loadable(() => import("../views/pages/home"),{
//     fallback: loading
// })

const Claim = loadable(() => import("../views/scaffolds/claim"),{
    fallback: loading
})

const End = loadable(() => import("../views/scaffolds/end"),{
    fallback: loading
})

// const Instruction = loadable(() => import("../views/pages/instruction"),{
//     fallback: loading
// })

const List = loadable(() => import("../views/scaffolds/list"),{
    fallback: loading
})

const Scan = loadable(() => import("../views/scaffolds/scan"),{
    fallback: loading
})


export default [
    // {
    //     path : '/',
    //     element : Home 
    // },
    {
        path : '/',
        element: List
    },
    {
        path : '/claim',
        element : Claim
    },
    {
        path : '/end',
        element: End
    },
    // {
    //     path : '/instruction',
    //     element: Instruction
    // },
    
    {
        path : '/scan/:product',
        element: Scan
    }
];
