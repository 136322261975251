import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { getArtefact} from '../../utils/gaia';
//import ToolBar from '../../Components/toolbar'<AppLanguage />
//import AppLanguage from '../../Components/appLanguage' <ToolBar banner={getArtefact("banner")} link="home" past="/" hasMenu={false} /> 
import routes from '../../utils/routes';
import { Images } from '../../utils/Images';
 
const Home = (props) => {
    return (
      <>
        <section className="common-bg">
          <div className="top-img">
          </div>
          <div className="bottom-img">
          </div>
          <div  className="logo">
            <a href="/app">
                <img src={Images.logo4x} alt="" />
            </a>
          </div>
          <Routes>
            {routes.map((route, idx) => (
              <Route exact path={route.path} key={idx} name="Landing Page" element={<route.element />} />
            ))}
          </Routes> 
        </section>
      </>
    );
}

export default Home;
