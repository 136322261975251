import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import { Images } from '../../utils/Images';
import {formatString, isLoggedIn, loadError} from '../../utils/gaia';
import TcModal from '../components/TcModal';


const Instruction = (props) => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [tc, setTC] = useState(false)

  const [TcModalOpen, setTcModalOpen] = useState(false)

  useEffect(()=>{
    if(!isLoggedIn()){navigate(`/`)}
  },[])

  const toApp = () =>{
    if(tc){navigate("/app")}
    else{loadError(formatString(t("accept_terms"),""))}
  }

  const onChange = (e) => {
    setTC(!tc)
  }

  return(
    <section className="common-bg">
      <div className="top-img"></div>
      <div className="bottom-img"></div>
      <div  className="logo">
        <a href="index.html">
            <img src={Images.logo4x} alt="" />
        </a>
      </div>
      <div className="instruction-page container">
        <div className="home-text">
            <img src={Images.Title_BeautyHunt} alt="" />
        </div>
        <div className="hero-sec">
            <div className="play-sec">
                <div className="hero-top-btn">
                    <button className="btn play-btn">{formatString(t("how_to_play"),"")}</button>
                </div>
                    <ul className="play-list">
                        <li>
                             <span className="count ">1</span> 
                             <div className="icon cutm-icon"><img src={Images.htp1} alt="" /></div> 
                             <h3>{formatString(t("htp_1"),"")} <br /><span>{formatString(t("3_b_treas"),"")}</span></h3>
                            </li>
                        <li>
                             <span className="count">2</span> 
                             <div className="icon"><img src={Images.htp2} alt="" /></div> 
                             <h3 dangerouslySetInnerHTML={{__html: formatString(t("htp_2"),"")}} />
                            </li>
                          <li>
                             <span className="count">3</span> 
                             <div className="icon"><img src={Images.htp3} alt="" /></div> 
                             <h3 dangerouslySetInnerHTML={{__html: formatString(t("htp_3"),"")}} />
                          </li>
                    </ul>
                </div>
                <form className="hero-checkbox">
                    <div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="tc" onChange={(e)=>onChange(e)} />
                            <label className="form-check-label" for="tc" onClick={()=>setTcModalOpen(true)}>{formatString(t("tc_approval"),"")}</label>
                        </div>
                    </div>
                </form>
                <div className="instruction-btn start-btn">
                   <a onClick={toApp}>{formatString(t("start"),"")}</a>
                </div>
        </div>
    </div>
    {TcModalOpen ? <TcModal open={TcModalOpen} controller={setTcModalOpen} /> : ""}
  </section>
  )
}

export default Instruction;
