export const Images = {
    logo4x : require("../assets/images/Logo@4x.png"),
    en_back : require("../assets/images/Button_Back@4x.png"),
    cn_back : require("../assets/images/CN_BACK.png"),
    box_name : require("../assets/images/Box_Name.png"),
    Title_BeautyHunt : require("../assets/images/Title_BeautyHunt@4x.png"),
    Bvlgari : require("../assets/images/im9.png"),
    NARS : require("../assets/images/im6.png"),
    Chloe : require("../assets/images/im2.png"),
    YSL : require("../assets/images/im7.png"),
    TF : require("../assets/images/im8.png"),
    HR : require("../assets/images/im4.png"),
    Armani : require("../assets/images/im1.png"),
    SKII : require("../assets/images/im10.png"),
    Clarins : require("../assets/images/im3.png"),
    Lancome : require("../assets/images/im5.png"),


    "Estee Lauder" : require("../assets/images/Item_EsteeLauder.png"),
    "Drunken Elephant" : require("../assets/images/Item_DE.png"),
    "Cle De Peau" : require("../assets/images/Item_CDP.png"),
    "Santal 33" : require("../assets/images/Item_LeLabo.png"),
    "SKII Pitera" : require("../assets/images/Item_SK2 Ppng.png"),
    
    rectangle : require("../assets/images/Rectangle 3919.png"),
    popup : require("../assets/images/2_7mb.gif"),
    scanning : require("../assets/images/LoadingWheel_2.5mb.gif"),
    left : require("../assets/images/left.png"),
    right : require("../assets/images/right.png"),
    htp1 : require("../assets/images/HowToPlay_Details@4x 1.png"),
    htp2 : require("../assets/images/HowToPlay_Details@4x 2.png"),
    htp3 : require("../assets/images/HowToPlay_Details@4x 3.png"),
    S_cn_Bvlgari : require("../assets/images/cn/STAMPED_Blvgari.png"),
    S_cn_NARS : require("../assets/images/cn/STAMPED_NARS.png"),
    S_cn_Chloe : require("../assets/images/cn/STAMPED_Chloe.png"),
    S_cn_YSL : require("../assets/images/cn/STAMPED_YSL.png"),
    S_cn_TF : require("../assets/images/cn/STAMPED_TF.png"),
    S_cn_HR : require("../assets/images/cn/STAMPED_HR.png"),
    S_cn_Armani : require("../assets/images/cn/STAMPED_Armani.png"),
    S_cn_SKII : require("../assets/images/cn/STAMPED_SKII.png"),
    S_cn_Clarins : require("../assets/images/cn/STAMPED_Clarins.png"),
    "S_cn_Estee Lauder" : require("../assets/images/cn/GoldStamp_EsteeLaude_CN.png"),
    "S_cn_Drunken Elephant" : require("../assets/images/cn/GoldStamp_DE_CN_.png"),
    "S_cn_Cle De Peau" : require("../assets/images/cn/GoldStamp_CDP_CN.png"),
    "S_cn_Santal 33" : require("../assets/images/cn/GoldStamp_LeLabo_CN.png"),
    "S_cn_SKII Pitera" : require("../assets/images/cn/GoldStamp_SK2 P_CN.png"),

    S_cn_Lancome : require("../assets/images/cn/STAMPED_Lancome.png"),
    S_en_Bvlgari : require("../assets/images/en/STAMPED_Blvgari.png"),
    S_en_NARS : require("../assets/images/en/STAMPED_NARS.png"),
    S_en_Chloe : require("../assets/images/en/STAMPED_Chloe.png"),
    S_en_YSL : require("../assets/images/en/STAMPED_YSL.png"),
    S_en_TF : require("../assets/images/en/STAMPED_TF.png"),
    S_en_HR : require("../assets/images/en/STAMPED_HR.png"),
    S_en_Armani : require("../assets/images/en/STAMPED_Armani.png"),
    S_en_SKII : require("../assets/images/en/STAMPED_SKII.png"),
    S_en_Clarins : require("../assets/images/en/STAMPED_Clarins.png"),
    S_en_Lancome : require("../assets/images/en/STAMPED_Lancome.png"),
    "S_en_Estee Lauder" : require("../assets/images/en/GoldStamp_EsteeLaude_EN.png"),
    "S_en_Drunken Elephant" : require("../assets/images/en/GoldStamp_DE_EN.png"),
    "S_en_Cle De Peau" : require("../assets/images/en/GoldStamp_CDP_EN.png"),
    "S_en_Santal 33" : require("../assets/images/en/GoldStamp_LeLabo_EN_x.png"),
    "S_en_SKII Pitera" : require("../assets/images/en/GoldStamp_SK2 P_EN.png"),

    BLVG_en : require("../assets/images/BLVG_en.png"),
    BLVG_cn : require("../assets/images/BLVG_cn.png"),

    

}