import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import {APIHOST,formatString} from '../../utils/gaia';
import { Images } from '../../utils/Images';


const NotFound = (props) => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(()=>{
  },[])

  return(
    <section className="common-bg">
        <div className="top-img">
        </div>
        <div className="bottom-img custm-index">
        </div>
        <div  className="logo">
            <a href="/app">
                <img src={Images.logo4x} alt="" />
            </a>
        </div>
        <div className="container">
            <div className="home-text">
                <img src={Images.Title_BeautyHunt} alt="" />
            </div>
            <div style={{marginTop : "250px", textAlign : "center"}}> Page not Found</div>
        </div>
    </section>
  )
}

export default NotFound;
