import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import { Images } from '../../utils/Images';
import { useTranslation} from 'react-i18next';
import Axios from 'axios';
import {APIHOST,formatString, getToken, showLoading, isLoggedIn, setLanguage, getLanguage, loadSuccess, loadError, actionError, updateloginStatus, setToken} from '../../utils/gaia';


const Landing = (props) => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [language] = useState(getLanguage());

  useEffect(()=>{
    //if(isLoggedIn()){navigate(`/app`)}
    if(typeof getToken() == "undefined"){createUser();}
  },[])

  const createUser = () =>{
    setLoading(true)
    showLoading("Please wait...")
    Axios.post(`${APIHOST}create`, {
    }).then(function(res){
        let {data} = res;
        updateloginStatus(true);
          setToken(data.candy);
          setLoading(false)
          loadSuccess(data.msg)
          //setTimeout(()=>{  window.location.href =  "/app" },1500)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          actionError(er.response.data.error ?? er.response.data.message, createUser)
        }else{
          actionError("A network error occured.", createUser)
        }
      }else{
        actionError(er.message, createUser)
      }
      
    })
  }
  const changeLang = (value) =>{
    setLanguage(value);
    window.location.href = `/instruction`
  }

  return(
      <section className="common-bg">
        <div className="top-img">
        </div>
        <div className="bottom-img custm-index">
        </div>
        <div  className="logo">
            <a href="/">
                <img src={Images.logo4x} alt="" />
            </a>
        </div>
        <div className="container">
            <div className="home-text">
                <img src={Images.Title_BeautyHunt} alt="" />
            </div>
            <div className="lang-sec">
                <div className="launguage-div">
                     <h3>{formatString(t("language"),"camel")}</h3>
                     <a onClick={()=>changeLang("en")} className="btn btn-eng">English</a>
                     <a onClick={()=>changeLang("cn")} className="btn btn-china">简体中文</a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Landing;
