import React, { useEffect,useState } from 'react';
import { Modal, ModalBody,} from 'reactstrap';
import { useTranslation} from 'react-i18next';
import { formatString } from '../../utils/gaia';

const TcModal = (props) => {
  const { t, i18n } = useTranslation();
    useEffect(()=>{
 
    },[props])

    return (
      < >
        <Modal className='modal-dialog-centered' isOpen={props.open} toggle={() => props.controller()}>
            <div class="text-end">
                <button type="button" class="btn-close" onClick={()=>props.controller()} aria-label="Close"></button>
            </div>   
            <ModalBody className="register-note" id="register">
                <h3 dangerouslySetInnerHTML={{__html: formatString(t("terms_cond_1"),"")}} />
                <p dangerouslySetInnerHTML={{__html: formatString(t("terms_cond_2"),"")}} />
                <p dangerouslySetInnerHTML={{__html: formatString(t("terms_cond_3"),"")}} />
                <p dangerouslySetInnerHTML={{__html: formatString(t("terms_cond_4"),"")}} />
            </ModalBody>
        </Modal>
      </>
    );
}

export default TcModal;