import React from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const PopSwal = withReactContent(Swal)

function Loading() {
  return <div>Loading...</div>;
}

export function showLoading(msg){
  PopSwal.fire({ 
    title: msg,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      PopSwal.showLoading();
    }
  });
}

export function loadSuccess(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'success',
    timer: 2000,
    showConfirmButton: false
  });
}

export function loadError(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    timer: 2000,
    showConfirmButton: true
  })
}

export function actionError(msg,action){
  PopSwal.fire({
    icon: 'error',
    title: msg,
    showCancelButton: false,
    confirmButtonColor: "#e72520",
    confirmButtonText: "Ok",
  }).then((result) => {
    if (result.isConfirmed) {
      action()
    }
  });  
}

export function updateloginStatus(status = false,expires=10000){
  Cookies.set("islogged", status, {path: "/", expires : expires});
}

export function setClaimed(status = false,expires=10000){
  Cookies.set("isclaimed", status, {path: "/", expires : expires});
}

export function setToken(token,expires=10000){
  Cookies.set("obulus", token, {path: "/", expires : expires});
}

export function getToken(){
  return Cookies.get("obulus");
}
export function isLoggedIn(){ 
  return Cookies.get("islogged"); 
}

export function isClaimed(){ 
  return Cookies.get("isclaimed"); 
}

export function destroySession(){
  Cookies.remove("obulus",{ path: '/' });
  Cookies.remove("islogged",{ path: '/' });
  Cookies.remove("isclaimed",{ path: '/' });
  Cookies.remove("argos",{ path: '/' });
}

let HOST = null;
let url = window.location.hostname;

if(url.includes("shillabeautyhunt.com")){
  HOST = "https://api.shillabeautyhunt.com";
}else{
  HOST = "https://api.shillabeautyhunt.com";
  //HOST = "http://127.0.0.1:9000";
}

export const APIHOST = `${HOST}/api/`

export function getLanguage(){
  return Cookies.get("locale");
}

export function setLanguage(token,expires=10000){
  Cookies.set("locale", token, {path: "/", expires : expires});
}

export function formatString(string, format){
  let parsed = "";
  switch(format) {
      case "upper":
          parsed = string.toUpperCase();
          break;
      case "lower":
          parsed = string.toLowerCase();
          break;
      case "sentence":
          parsed = string.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
          {
              return index == 0 ? word.toLowerCase() : word.toUpperCase();
          }).replace(/\s+/g, '');
          break;
      case "camel":
          let str = string.toLowerCase().split(' ');
          for (var i = 0; i < str.length; i++) {
              str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
          }
          parsed = str.join(' ');
          break;
      default:
        parsed = string;
  }

  return parsed;
  
}